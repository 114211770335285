import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import rapidbevtechlogo from "../assets/images/rapid-bevtech-logo.svg";
import rightarrow from "../assets/images/rightarrow.png";
import hamburger from "../assets/images/hamburger.png";
import downarrow from "../assets/images/down-arrow.png";
import MegaMenu from "./MegaMenu";
import MobileViewSidebar from "./MobileViewSidebar";
import mainsend from "../assets/images/temp/send-mail.png";
import highlightlogo from "../assets/images/Rapid Highlight.svg";
import "../assets/css/headermain.css";

const HeaderMain = ({ setShowContactPopup, openContactPopup }) => {
  const [scrolled, setScrolled] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const navigate = useNavigate();
  const megaMenuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function openContactPopupheader() {
    openContactPopup();
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
        setShowMegaMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleMenuClick = () => {
    setShowMegaMenu(!showMegaMenu);
  };

  const openSideBarFun = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleMouseEnter = () => {
    setShowMegaMenu(true);
  };

  const handleMouseEnterHeader = () => {
    if (showMegaMenu) {
      setShowMegaMenu(true);
    }
  };

  return (
    <>
      <div>
        <div
          onMouseEnter={handleMouseEnterHeader}
          className={showMegaMenu ? `header-container-white ${scrolled ? "scrolled" : ""}` : `header-container ${scrolled ? "scrolled" : ""}`}
        >
          <div className="row">
            <div onClick={() => handleNavigation("/")} className="col-2 rapidbevtechlogo-wrapper">
              <img
                className={
                  location.pathname === "/highlights"
                    ? `rapidbevtechlogo rapidbevtechlogohighlights ${scrolled ? "scrolled" : ""}`
                    : `rapidbevtechlogo ${scrolled ? "scrolled" : ""}`
                }
                src={location.pathname === "/highlights" ? highlightlogo : rapidbevtechlogo}
                alt="rapid logo"
              />
            </div>
            <div className="col-8 headermain-menu">
              <ul className={`header-menus ${scrolled ? "scrolled" : ""}`}>
                <li className="header-menus-list">
                  <a
                    onClick={() => handleNavigation("/about-us")}
                    className={showMegaMenu ? `header-menu-txt-black ${scrolled ? "scrolled" : ""}` : `header-menu-txt ${scrolled ? "scrolled" : ""}`}
                    href="#"
                  >
                    About us
                  </a>
                </li>
                <li className="header-menus-list hover-special" onMouseEnter={handleMouseEnter}>
                  <div onClick={handleMenuClick} className="wrapper-mega-menu-img">
                    <a
                      className={
                        showMegaMenu
                          ? `header-menu-txt-black mega-menu-special ${scrolled ? "scrolled" : ""}`
                          : `header-menu-txt mega-menu-special ${scrolled ? "scrolled" : ""}`
                      }
                      href="#"
                    >
                      Solutions
                    </a>
                    <img
                      className={showMegaMenu ? `mega-menu-down-arrow-img-black ${scrolled ? "scrolled" : ""}` : `mega-menu-down-arrow-img ${scrolled ? "scrolled" : ""}`}
                      src={downarrow}
                      alt=""
                    />
                  </div>
                </li>
                <li className="header-menus-list">
                  <a
                    onClick={() => handleNavigation("/turnkey")}
                    className={showMegaMenu ? `header-menu-txt-black ${scrolled ? "scrolled" : ""}` : `header-menu-txt ${scrolled ? "scrolled" : ""}`}
                    href="#"
                  >
                    Turnkey
                  </a>
                </li>
                <li className="header-menus-list">
                  <a
                    onClick={() => handleNavigation("/resale")}
                    className={showMegaMenu ? `header-menu-txt-black ${scrolled ? "scrolled" : ""}` : `header-menu-txt ${scrolled ? "scrolled" : ""}`}
                    href="#"
                  >
                    Resale
                  </a>
                </li>
                <li className="header-menus-list">
                  <a
                    onClick={() => handleNavigation("/services")}
                    className={showMegaMenu ? `header-menu-txt-black ${scrolled ? "scrolled" : ""}` : `header-menu-txt ${scrolled ? "scrolled" : ""}`}
                    href="#"
                  >
                    Services
                  </a>
                </li>
                <li className="header-menus-list">
                  <a
                    onClick={() => handleNavigation("/highlights")}
                    className={showMegaMenu ? `header-menu-txt-black ${scrolled ? "scrolled" : ""}` : `header-menu-txt ${scrolled ? "scrolled" : ""}`}
                    href="#"
                  >
                    Highlights
                  </a>
                </li>
                <li className="header-menus-list">
                  <a
                    onClick={() => handleNavigation("/contact-us")}
                    className={showMegaMenu ? `header-menu-txt-black ${scrolled ? "scrolled" : ""}` : `header-menu-txt ${scrolled ? "scrolled" : ""}`}
                    href="#"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div onClick={openContactPopupheader} className={`header-send-inquire col-2 ${scrolled ? "scrolled" : ""}`}>
              <div className="all-project-btn-inquiry all-project-btn-inquiry-header">
                <div className="all-project-btn-txt-inquiry">Send Inquiry</div>
                <span className="right-arrow-span-inquiry">
                  <img className="right-arrow-inquiry" src={rightarrow} alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img onClick={openContactPopupheader} className={`sendmail-img ${scrolled ? "scrolled" : ""}`} src={mainsend} alt="Hamburger Menu" />
        </div>
        <div onClick={openSideBarFun}>
          <img className={`hamburger-img ${scrolled ? "scrolled" : ""}`} src={hamburger} alt="Hamburger Menu" />
        </div>
      </div>
      {showMegaMenu && (
        <div className="overlay-mm">
          <div ref={megaMenuRef}>
            <MegaMenu scrolled={scrolled} setShowMegaMenu={setShowMegaMenu} />
          </div>
        </div>
      )}
      {openSideBar && (
        <div className="overlay-mm">
          <MobileViewSidebar setOpenSideBar={setOpenSideBar} openContactPopup={openContactPopup}/>
        </div>
      )}
    </>
  );
};

export default HeaderMain;
