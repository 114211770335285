import rapidbevtechlogo from "../assets/images/rapid-bevtech-logo.svg";
import hamburger from "../assets/images/hamburger.png";
import "../assets/css/headermain.css";
import { useState } from "react";
import MobileViewSidebar from "./MobileViewSidebar";
import { useNavigate } from "react-router-dom";
import mainsend from "../assets/images/temp/send-mail.png"

const HeaderTwo = ({setShowContactPopup, openContactPopup}) => {
  const [openSideBar, setOpenSideBar] = useState(false)
  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate("/");
  };
  return (
    <>
    <div className={"header-two-wrapper"}>
      <img onClick={goToHomePage} className="header-two-logo" src={rapidbevtechlogo} alt="Rapid Bevtech logo" />
      <div>
          <img onClick={openContactPopup} className={"send-mail-two"} src={mainsend} alt="Hamburger Menu" />
      <img onClick={() => setOpenSideBar(true)} className="header-two-burger" src={hamburger} alt="Hamburger menu" />
        </div>
    </div>
      {openSideBar && 
      <MobileViewSidebar setOpenSideBar={setOpenSideBar} openContactPopup={openContactPopup}  />
      }
    </>
  );
};

export default HeaderTwo;
