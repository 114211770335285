import React from "react";
import "../assets/css/megamenu.css";
import rightarrow from "../assets/images/rightarrow.png";
import img1 from "../assets/images/Beer-swiper.webp";
import img3 from "../assets/images/csdswiper.webp";
import { useNavigate } from "react-router-dom";
const MegaMenu = ({ setShowMegaMenu, scrolled }) => {
  const navigate = useNavigate();

  const redirectToSolutions = () => {
    navigate("solutions/beer-in-can");
  };
  const redirectToBeerInGlass = () => {
    navigate("solutions/beer-in-glass-botttles");
  };
  const redirectToCsd = () => {
    navigate("solutions/carbonated-soft-drink-in-can");
  };
  const RedirectToBeerInKeg = () => {
    navigate("solutions/beer-in-keg");
  };
  const RedirectToProcessSystemBeer = () => {
    navigate("/solutions/process-system-beer");
  };
  const RedirectToProcessSystemCsd = () => {
    navigate("solutions/process-system-csd");
  };
  const handleMouseLeave = () => {
    setShowMegaMenu(false);
  };
  return (
    <div
      className={scrolled ? "mega-menu-main-scrolled" : "mega-menu-main"}
      onMouseLeave={handleMouseLeave}
    >
      <div className="megamenu-inner-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="mm-initial-div-wrap">
                <div className="inner-wrapper-mm-one">
                  <div className="mm-img-wrapper">
                    <img src={img1} alt="" />
                  </div>
                  <div className="mm-btns-wrapper">
                    <a
                      href="#"
                      className="btn-mega-menu"
                      onClick={redirectToSolutions}
                    >
                      BEER IN CAN
                    </a>
                    <a
                      href="#"
                      onClick={RedirectToBeerInKeg}
                      className="btn-mega-menu"
                    >
                      BEER IN KEG
                    </a>
                    <a
                      href="#"
                      className="btn-mega-menu"
                      onClick={redirectToBeerInGlass}
                    >
                      BEER IN GLASS BOTTLES
                    </a>
                    <a
                      href="#"
                      onClick={RedirectToProcessSystemBeer}
                      className="btn-mega-menu"
                    >
                      PROCESS SYSTEM
                    </a>
                  </div>
                </div>
                <div className="inner-wrapper-mm-one">
                  <div className="mm-img-wrapper">
                    <img src={img3} alt="" />
                  </div>
                  <div className="mm-btns-wrapper">
                    <a
                      href="#"
                      className="btn-mega-menu"
                      onClick={redirectToCsd}
                    >
                      CSD IN CAN
                    </a>
                    <a
                      href="#"
                      className="btn-mega-menu"
                      onClick={RedirectToProcessSystemCsd}
                    >
                      PROCESS SYSTEM
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sapratoe-line"></div>
          <div className="under-line-btn">
            <p className="p-txt-mm">
              Check out all the impact we have created so far
            </p>
            <button className="btn-mm-wrapper">
              <div className="txt-img-mm-wrapper">
                <p className="txt-mm-btn">View All Work</p>
                <span className="img-wrapper-mm">
                  <img className="right-arrow-mm-img" src={rightarrow} alt="" />
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
