import React from "react";
import "../assets/css/contactpopup.css";
import thinkingvector from "../assets/images/Beer-swiper.webp";
import rightarrow from "../assets/images/rightarrow.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import closebtn from "../assets/images/close.png";

const ContactPopUp = ({ closeContactPopup, showContactPopup }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    telephone: "",
    type: "",
    organization: "",
    messageText: "",
    terms: false,
    pageurl: "",
  });
  const [validationMessages, setValidationMessages] = useState({
    fullName: "",
    email: "",
    telephone: "",
    type: "",
    organization: "",
    messageText: "",
    terms: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Update form data
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "type") {
      setValidationMessages((prevState) => ({
        ...prevState,
        [name]: "", // Clear validation message for dropdown
      }));
    }
    // Re-validate the form field after updating form data
    if (type !== "checkbox") {
      const isValid = validateField(name, value);

      // Remove validation message if field becomes valid
      if (isValid) {
        setValidationMessages((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      }
    }
    // Re-validate the form field after updating form data
    const isValid = validateField(name, value);

    // Remove validation message if field becomes valid
    if (isValid) {
      setValidationMessages({
        ...validationMessages,
        [name]: "",
      });
    }
  };

  const validateField = (fieldName, fieldValue) => {
    let isValid = true;
    // Perform validation based on field name and value
    switch (fieldName) {
      case "fullName":
        if (!fieldValue.trim()) {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "This field is required",
          });
          isValid = false;
        } else if (fieldValue.length > 40) {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "Full name cannot exceed 40 characters",
          });
          isValid = false;
        }
        break;
      case "email":
        if (!fieldValue.trim() || !/\S+@\S+\.\S+/.test(fieldValue)) {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "Invalid email format",
          });
          isValid = false;
        }
        break;

      case "organization":
        if (!fieldValue.trim()) {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "This field is required",
          });
          isValid = false;
        } else if (fieldValue.length > 40) {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "Name of Organization cannot exceed 40 characters",
          });
          isValid = false;
        }
        break;
      case "messageText":
        if (!fieldValue.trim()) {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "This field is required",
          });
          isValid = false;
        }
        break;
      case "type":
        if (fieldValue !== "Provider" && fieldValue !== "Customer") {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "Please select a valid type",
          });
          isValid = false;
        } else {
          setValidationMessages({
            ...validationMessages,
            [fieldName]: "",
          });
        }
        break;

      // Add cases for other fields as needed
      default:
        break;
    }

    return isValid;
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationMessages = {};

    // Validation for Full Name
    if (!formData.fullName.trim()) {
      newValidationMessages.fullName = "This field is required";
      isValid = false;
    }

    // Validation for Email
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newValidationMessages.email = "Invalid email format";
      isValid = false;
    }
    // Validation for Telephone
    if (!formData.telephone.trim()) {
      newValidationMessages.telephone = "This field is required";
      isValid = false;
    } else if (formData.telephone.length !== 10) {
      newValidationMessages.telephone =
        "Telephone number must be exactly 10 digits";
      isValid = false;
    }

    // Validation for Type
    if (formData.type !== "Provider" && formData.type !== "Customer") {
      newValidationMessages.type = "This field is required";
      isValid = false;
    }

    // Validation for Organization
    if (!formData.organization.trim()) {
      newValidationMessages.organization = "This field is required";
      isValid = false;
    }

    // Validation for Message Text
    if (!formData.messageText.trim()) {
      newValidationMessages.messageText = "This field is required";
      isValid = false;
    }

    if (!formData.terms) {
      newValidationMessages.terms =
        "You must read and accept the terms and conditions";
      isValid = false;
    }

    setValidationMessages(newValidationMessages);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, submit data
      console.log("Form is valid:", formData);
      setFormData({
        fullName: "",
        email: "",
        telephone: "",
        type: "",
        organization: "",
        messageText: "",
        terms: false,
      });
      try {
        const response = await axios.post(
          "https://www.rapidbevtech.com/api/mail.php",
          {
            ...formData,
          }
        );

        console.log("Response data:", response);

        if (response.data.message === "Email Send Done!") {
          console.log("Email successfully sent.");
          closeContactPopup();
          navigate("/thank-you");
        } else {
          console.error("Unexpected response message:", response.data.message);
        }
      } catch (error) {
        if (error.response) {
          console.error("Error response:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        } else if (error.request) {
          console.error("Error request:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        console.error("Error config:", error.config);
      }
    } else {
      // Form is invalid, do not submit
      console.log("Form is invalid");
    }
  };

  useEffect(() => {
    setFormData({ ...formData, pageurl: window.location.href });
  }, []);

  return (
    <div className="pop-up-container-wrapper">
      <div className="pop-up-container container fade-in-up">
        <div className="row pop-up-row">
          <div className="col-6 left-side-popup">
            <div>
              <p className="left-side-heading-txt-pop-up">
                Let's Scale Your Brand, Together
              </p>
              {/* <p className="pop-up-txt-left-p">
                Get a{" "}
                <a className="pop-up-link" href="mailto:admin@rapidbevtech.com">
                  admin@rapidbevtech.com
                </a>
              </p> */}
            </div>
            <div className="left-img-pop-up">
              <img src={thinkingvector} alt="" />
            </div>
          </div>
          <div className="col-6 right-side-popup">
            <div className="pop-up-contact-form-wrapper">
              <form onSubmit={handleSubmit} className="pop-up-form">
                <p className="pop-up-label-txt">Full name *</p>
                <input
                  className="input-pop-up"
                  placeholder="Full name"
                  name="fullName"
                  type="text"
                  maxLength={40}
                  value={formData.fullName}
                  onChange={handleChange}
                />
                {validationMessages.fullName && (
                  <p className="validation-message-pop-up">
                    {validationMessages.fullName}
                  </p>
                )}
                <p className="pop-up-label-txt">Email *</p>
                <input
                  className="input-pop-up"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                />
                {validationMessages.email && (
                  <p className="validation-message-pop-up">
                    {validationMessages.email}
                  </p>
                )}
                <p className="pop-up-label-txt">Telephone *</p>
                <input
                  className="input-pop-up"
                  type="text"
                  placeholder="Telephone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                    if (value.length <= 10) {
                      handleChange(e); // Call handleChange explicitly here
                    }
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  pattern="[0-9]{10}"
                />
                {validationMessages.telephone && (
                  <p className="validation-message-pop-up">
                    {validationMessages.telephone}
                  </p>
                )}
                <p className="pop-up-label-txt">Name of the organization *</p>
                <input
                  className="input-pop-up"
                  type="text"
                  placeholder="Name of the organization"
                  value={formData.organization}
                  onChange={handleChange}
                  name="organization"
                />
                {validationMessages.organization && (
                  <p className="validation-message-pop-up">
                    {validationMessages.organization}
                  </p>
                )}
                <p className="pop-up-label-txt">Type of Organization *</p>
                <select
                  className="select-pop-up"
                  name="type"
                  onChange={handleChange}
                  value={formData.type}
                >
                  <option className="default-option" hidden value="">
                    Select from list
                  </option>
                  <option className="option-pop-up" value="Provider">
                    Provider
                  </option>
                  <option className="option-pop-up" value="Customer">
                    Customer
                  </option>
                </select>
                {validationMessages.type && (
                  <p className="validation-message-pop-up">
                    {validationMessages.type}
                  </p>
                )}
                <p className="pop-up-label-txt">Message text *</p>
                <textarea
                  className="input-pop-up"
                  placeholder="Describe what equipment you need"
                  value={formData.messageText}
                  onChange={handleChange}
                  name="messageText"
                />
                {validationMessages.messageText && (
                  <p className="validation-message-pop-up">
                    {validationMessages.messageText}
                  </p>
                )}
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={formData.terms}
                    name="terms"
                    onChange={handleChange}
                  />
                  <p>
                    I agree to the processing of my personal data in accordance
                    with the terms
                  </p>
                </div>
                {validationMessages.terms && (
                  <p className="validation-message-pop-up">
                    {validationMessages.terms}
                  </p>
                )}
                <button className="pop-up-submit-btn" type="submit">
                  <p className="pop-btn-txt">Send Inquiry</p>
                  <div className="pop-up-right-arrow-wrapper">
                    <img
                      className="pop-up-right-arrow"
                      src={rightarrow}
                      alt=""
                    />
                  </div>
                </button>
              </form>
              <div
                onClick={closeContactPopup}
                className="close-btn-wrapper-pop-up"
              >
                  <img src={closebtn} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPopUp;
