import React from "react";
import cross from "../assets/images/plus.png";
import one from "../assets/images/File.svg";
import two from "../assets/images/email.svg";
import three from "../assets/images/call.svg";
import four from "../assets/images/Customer Support.svg";
import { useState } from "react";

const ShortCuts = ({setShowContactPopup}) => {
  const [open, setOpen] = useState(false);
  
  function changeIcon() {
    setOpen(!open);
  }

  return (
    <div>
      <div className={`wrapper-short-cuts ${open ? 'open' : ''}`}>
        <div onClick={() => setShowContactPopup(true)} className="short-cuts-one">
          <img src={one} alt="scroll to top" />
        </div>
        <div className="short-cuts-two">
          <a href="mailto:admin@rapidbevtech.com">
            <img src={two} alt="scroll to top" />
          </a>
        </div>
        <div className="short-cuts-three">
          <a href="tel:+917567217398">
            <img src={three} alt="scroll to top" />
          </a>
        </div>
      </div>
      <div onClick={changeIcon} className={open ? "cross-short-cuts" : "short-cuts"}>
        <img src={open ? cross : four} alt="scroll to top" />
      </div>
    </div>
  );
};

export default ShortCuts;
