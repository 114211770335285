import React, { useState } from "react";
import "../assets/css/megamenu.css";
import rapidlogo from "../assets/images/rapid-bevtech-logo.svg";
import closebtn from "../assets/images/close.png";
import rightarrow from "../assets/images/rightarrow.png";
import downarrow from "../assets/images/down-arrow.png";
import hamburger from "../assets/images/hamburger.png";
import { useNavigate } from "react-router-dom";
import img1 from "../assets/images/Beer-swiper.webp";
import img3 from "../assets/images/csdswiper.webp";
const MobileViewSidebar = ({ setOpenSideBar, showContactPopup, openContactPopup }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const navigate = useNavigate();

  const redirectToAboutUs = () => {
    navigate("/about-us");
    setOpenSideBar(false)
  };
  const redirectToContactUs = () => {
    navigate("/contact-us");
    setOpenSideBar(false)
  };
  const goToTurnKey = () => {
    navigate("/turnkey");
    setOpenSideBar(false)
  };
  const goToResale = () => {
    navigate("/resale");
    setOpenSideBar(false)
  };
  const goToServices = () => {
    navigate("/services");
    setOpenSideBar(false)
  };
  const redirectToSolutions = () => {
    navigate("solutions/beer-in-can");
    setOpenSideBar(false)
  };
  const redirectToBeerInGlass = () => {
    navigate("solutions/beer-in-glass-botttles");
    setOpenSideBar(false)
  };
  const redirectToCsd = () => {
    navigate("solutions/carbonated-soft-drink-in-can");
    setOpenSideBar(false)
  };
  const redirectToBeerInKeg = () => {
    navigate("solutions/beer-in-keg");
    setOpenSideBar(false)
  };
  const RedirectToProcessSystemBeer = () => {
    navigate("/solutions/process-system-beer")
    setOpenSideBar(false)
  }
  const RedirectToProcessSystemWater = () => {
    navigate("/process-system-water")
    setOpenSideBar(false)
  }
  const RedirectToProcessSystemCsd = () => {
    navigate("solutions/process-system-csd")
    setOpenSideBar(false)
  }
  const redirectToHomePage = () => {
    setOpenSideBar(false)
    navigate("/")
  }
  
  const goToHighlights = () => { 
    navigate("/highlights");
    setOpenSideBar(false)
  }
console.log(showContactPopup);
  
  return (
    <div>
      <div className="side-bar-wrapper">
        <div className="sidebar-top-imgs-wrapper">
          <img onClick={redirectToHomePage} className="side-bar-logo" src={rapidlogo} alt="" />
          <img
            onClick={() => setOpenSideBar(false)}
            className="close-icon"
            src={closebtn}
            alt=""
          />
        </div>
        <div className="sidebar-ul-wrapper">
          <ul className="sidebar-ul">
            <li>
              <a onClick={redirectToAboutUs} className="sidebar-links" href="#">
                About us
              </a>
            </li>
            <li>
              <div onClick={() => setOpenSubMenu(true)}>
                <a className="sidebar-links" href="#">
                  Solutions
                </a>
                <img className="down-arrow-sidebar" src={downarrow} alt="" />
              </div>
            </li>
            <li>
              <a onClick={goToTurnKey} className="sidebar-links" href="#">
                Turnkey
              </a>
            </li>
            <li>
              <a onClick={goToResale} className="sidebar-links" href="#">
                Resale
              </a>
            </li>
            <li>
              <a onClick={goToServices} className="sidebar-links" href="#">
                Services
              </a>
            </li>
            <li>
              <a onClick={goToHighlights} className="sidebar-links" href="#">
                Highlights
              </a>
            </li>
            <li>
              <a
                onClick={redirectToContactUs}
                className="sidebar-links"
                href="#"
              >
                Contact us
              </a>
            </li>
          </ul>
        </div>
        <div className="sidebar-bottom-wrapper">
          <p className="p-txt-mm-sidebar">Let's create experiences together.</p>
          <button onClick={() => openContactPopup(true)} className="btn-mm-wrapper">
            <div className="txt-img-mm-wrapper">
              <p className="txt-mm-btn">Let's Talk</p>
              <span className="img-wrapper-mm">
                <img className="right-arrow-mm-img" src={rightarrow} alt="" />
              </span>
            </div>
          </button>
        </div>
      </div>
      {openSubMenu && (
        <>
          <div className="submenu-wrapper-sidebar">
            <div className="sidebar-top-imgs-wrapper">
              <img className="side-bar-logo" src={rapidlogo} alt="" />
              <img
                onClick={() => setOpenSubMenu(false)}
                className="close-icon"
                src={hamburger}
                alt=""
              />
            </div>
            <div>
                <img className="sidebar-img-btns" src={img1} alt="" />
                <div className="btns-submenu-wrapper">

                <a onClick={redirectToSolutions} className="sidebar-submenu-a" href="#">BEER IN CAN</a>
                <a onClick={redirectToBeerInKeg} className="sidebar-submenu-a" href="#">BEER IN KEG</a>
                <a onClick={redirectToBeerInGlass} className="sidebar-submenu-a" href="#">BEER IN GlASS BOTTLES</a>
                {/* <a className="sidebar-submenu-a" href="">CRAFT BREWERY SOLUTIONS</a> */}
                <a onClick={RedirectToProcessSystemBeer} className="sidebar-submenu-a" href="#">PROCESS SYSTEM</a>
                </div>
            </div>
            <div>
                <img className="sidebar-img-btns" src={img3} alt="" />
                <div className="btns-submenu-wrapper">
                {/* <a className="sidebar-submenu-a" href="">CSD IN GLASS</a>
                <a className="sidebar-submenu-a" href="">CSD IN PET</a> */}
                <a onClick={redirectToCsd} className="sidebar-submenu-a" href="#">CSD IN CAN</a>
                <a onClick={RedirectToProcessSystemCsd} className="sidebar-submenu-a" href="#">PROCESS SYSTEM</a>
                </div>
            </div>
            {/* <div>
                <img className="sidebar-img-btns" src={img4} alt="" />
                <div className="btns-submenu-wrapper">
                <a className="sidebar-submenu-a" href="">WATER IN PET</a>
                <a className="sidebar-submenu-a" href="">WATER IN JAR</a>
                <a onClick={RedirectToProcessSystemWater} className="sidebar-submenu-a" href="">PROCESS SYSTEM</a>
                </div>
            </div>
            <div className="last-megamenu">
                <img className="sidebar-img-btns" src={img2} alt="" />
                <div className="btns-submenu-wrapper">
                <a className="sidebar-submenu-a" href="">JUICE IN PET</a>
                <a className="sidebar-submenu-a" href="">JUICE IN CAN</a>
                <a className="sidebar-submenu-a" href="">PROCESS SYSTEM</a>
                </div>
            </div> */}
          </div>
        </>
      )}
      
    </div>
  );
};

export default MobileViewSidebar;
